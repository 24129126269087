.scoreItems{
    padding-bottom: 25px;
    &:nth-child(2){
        padding: 25px 0;
        border-top: 1px solid #CACACA;
        @media (max-width:1440px){
            border-top: unset;
        }
    }
    &:last-child{
        padding: 25px 0;
        border-top: 1px solid #CACACA;
        @media (max-width:1440px){
            border-top: unset;
        }
    }
    @media (max-width:1440px){
        padding: 25px 0;
        border-top: unset
        
    }
}