.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Login Page Style */
.authLayout {
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.authLayout .form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.authLayout .form-signin h1 {
  text-transform: uppercase;
  font-weight: bold;
}

.authLayout .form-signin .checkbox {
  font-weight: 400;
}

.authLayout .form-signin .form-check {
  display: inline-block;
}

.authLayout .form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.authLayout .form-signin .form-control:focus {
  z-index: 2;
}

.authLayout .form-signin input[type=email] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.authLayout .form-signin input[type=password] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

label.upload-btn {
  /* display: inline-block; */
  /* background-color: indigo; */
  /* color: white; */
  /* padding: 0.5rem; */
  /* font-family: sans-serif; */
  /* border-radius: 0.3rem; */
  /* cursor: pointer; */
}

/* flipcss */

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.flip-card-inner {
  position: relative;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.rotateY {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}