@import 'fonts/fonts.css';

*{
    font-family: 'Jost', sans-serif;
}

.warning{
    background-color: #FDF7E8;
    .percent-color{
         color: #D88913;
    }
}
.alert{
    background-color: #FEEDF0;
    .percent-color{
       color: #F7284A;
    }
}
.insight{
    background-color: #EAF0FF;
    .percent-color{
      color: #378EA5;
    }
}

.insight-header{
    &.issue{
        background-color: #F7284A;
     }
     &.warning{
        background-color: #D88913;
     }
     &.oppurtunity{
        background-color: #378EA5;
     }
    
}
.issue-border{
    border-color: #F7284A;
    .percent-color{
        color: #F7284A;
    }
 }
 .oppurtunity-border{
    border-color: #378EA5;
    .percent-color{
        color: #378EA5;
    }
 }
.warning-border{
    border-color: #D88913;
    .percent-color{
        color: #D88913;
    }
 }
.remove-arrow{
    &::-webkit-inner-spin-button,&::-webkit-outer-spin-button 
        {
            -webkit-appearance: none;
            margin: 0;
        }
}

 .tick-value,.tick-line {
    text {
        text-shadow: unset !important;
        fill: #000 !important;
       font-size: 14px !important;
    }
 }
 .outerSubArc{
    path{
        fill: #fff !important;
    }
 }
 .value-text{
    text{
        text-shadow: unset !important;
        fill: #2A2A2A !important;
        font-size: 48px !important;
        font-weight: 600 !important;

    }
 }
 .area-chart{
    .recharts-wrapper {
        width: 100% !important;
        margin: 0 auto;
        overflow-x: hidden;
    }
 }
 .recharts-wrapper{
    @media(min-width: 1280px){
    &.pie-chart{
            // width: 300px !important;
            // svg{
            //     width: 300px !important;
            // }

        }
       
    }
 }

 .sales-report{
    .recharts-wrapper{
        width: 100% !important;
        overflow-x: auto !important;
       
    }
 }

 .date-picker{
    
    .react-datepicker-wrapper{
        width: 100%;
        input{
            width: 100%;
            border: 1px solid #d2d6da;
            border-radius: 7px;
            display: flex;
            padding: 0.625rem;
            font-size: 14px;
            color: #000000;
            &::placeholder{
                font-size: 14px;
                color: #9197B3;
            }
        }
        .react-datepicker__input-container{
            .react-datepicker__calendar-icon{
                padding: 0;
                right: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: 0;
                top: 10px;
                flex-direction: column;
                fill: #9197B3;
            }
        }
    }
    
 }


 .menu{
    &.active{
        color: #157810;
        img{
            filter: brightness(0) saturate(100%) invert(28%) sepia(98%) saturate(699%) hue-rotate(78deg) brightness(99%) contrast(94%);
        }
    }
 }



    .recharts-wrapper{
        &.pie-chart{
            width: 100% !important;
            svg{
                width: 100% !important;
                text-align: center;
            }
        }
       
    }
 
