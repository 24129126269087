@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?lgv3gn');
  src:  url('fonts/icomoon.eot?lgv3gn#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?lgv3gn') format('truetype'),
    url('fonts/icomoon.woff?lgv3gn') format('woff'),
    url('fonts/icomoon.svg?lgv3gn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-filter:before {
  content: "\e911";
}
.icon-arrow-up:before {
  content: "\e926";
}
.icon-arrow-down:before {
  content: "\e926";
}
.icon-menuback:before {
  content: "\e948";
}
.icon-bulb:before {
  content: "\e904";
}
.icon-dashboard-icon:before {
  content: "\e905";
}
.icon-info-alert:before {
  content: "\e906";
}
.icon-logout:before {
  content: "\e907";
}
.icon-recomendation-circle:before {
  content: "\e908";
}
.icon-recommendation:before {
  content: "\e909";
}
.icon-settings:before {
  content: "\e90a";
}
.icon-support:before {
  content: "\e90b";
}
.icon-toggle-menu:before {
  content: "\e90c";
}
.icon-warning:before {
  content: "\e90d";
}
.icon-alert:before {
  content: "\e90e";
}
.icon-analytics:before {
  content: "\e90f";
}
.icon-analytics-icon:before {
  content: "\e910";
}
.icon-analytics1:before {
  content: "\e900";
}
.icon-dashboard-icon1:before {
  content: "\e901";
}
.icon-recommendation1:before {
  content: "\e902";
}
.icon-settings1:before {
  content: "\e903";
}
