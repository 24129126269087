.menuMainWrapper{
  background-color: #F9F9F9;
   &::-webkit-scrollbar{
    display: none;
   }
  li{
    &.active{
        color: #157810;
    }
  }
  .sublistsettings{
    li{
      position: relative;
      &::before{
        position: absolute;
        left: 0;
        content: "";
        width: 23px;
        height: 42px;
        top: -10px;
        background-image: url(../../../shared/assets/svg/line-arc.svg);
        @media (max-width:992px) {
            display: none;
            content: unset;
            position: unset;
        }
      }
    }
  }
}
